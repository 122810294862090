import React from 'react'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { BaseText, BoldText, SemiBoldText } from 'components/common/text'
import { SlickSlider } from 'components/common/slider'
import Image from 'next/legacy/image'
import { MyLink } from 'components/common/link'

interface DevReview {
  id: number
  icon: string
  desc: string
  writer: string
  interviewLink: string
}

const DEV_REVIEW_DATA: DevReview[] = [
  {
    id: 1,
    icon: '/tain-ai.svg',
    desc: '한 명도 편하게 월급을 받아가시려는 분이 없는\n모두가 열심히 일하는 분위기의 회사에 매우 만족하고 있습니다.\n슬랙을 통한 팀간의 소통과 기록이 매우 활발하여\n회사 전체 방향을 쉽게 팔로우업 할 수 있습니다.',
    writer: '프론트엔드 개발자 안*진',
    interviewLink: 'https://youtu.be/OgGbqN4r5aE?si=KZFmC28jcoCqfq1Y',
  },
  {
    id: 2,
    icon: '/lotlas.svg',
    desc: '아이데이션부터 개발까지 모든 과정을 함께하고\n유저 클로즈 베터를 통해 피드백을 들으면서\n제품을 개선하는 과정을 경험하여 매우 즐겁습니다.',
    writer: '프론트엔드 개발자 박*훈',
    interviewLink: 'https://youtu.be/h0uIDieeEJ8?si=fJ2ybuN7E7QD-jfH',
  },
  {
    id: 3,
    icon: '/clacorporation.svg',
    desc: '자신의 의견을 가감없이 편하게 말할 수 있고 동료들도\n편하게 피드백을 주고받는 문화가 형성되어있는 점이 가장 좋았습니다.\n스스로 성장하실 수 있는 분에게 적극 추천합니다.',
    writer: '백엔드 개발자 최*석',
    interviewLink: 'https://youtu.be/Gc8dUdsllDw?si=ndShETPz1opmLgGu',
  },
]

export const ScoutLandingStartupDescription: React.FC = () => {
  return (
    <Main>
      <HeadingText>그룹바이를 통해서 합류한</HeadingText>
      <MainText>
        검증된 스타트업은
        <br /> 이렇다고 해요
      </MainText>
      <SliderWrapper>
        <SlickSlider settings={{ autoplay: true }}>
          {DEV_REVIEW_DATA.map((item) => (
            <Form key={item.id} data={item} />
          ))}
        </SlickSlider>
      </SliderWrapper>
    </Main>
  )
}

const Form: React.FC<{ data: DevReview }> = ({ data }) => {
  const { icon, desc, writer, interviewLink } = data
  return (
    <FormContainer>
      <Image src={icon} width={180} height={120} alt={icon} priority />
      <DescText>{desc}</DescText>
      <WriterText>{writer}</WriterText>
      <MyLink href={interviewLink} newTab>
        <InterviewLink>인터뷰 보기</InterviewLink>
      </MyLink>
    </FormContainer>
  )
}

const DescText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    font-size: ${(p) => p.theme.fontSize.default};
  }
  font-size: 20px;
  line-height: 1.6;
  color: ${(p) => p.theme.color.black4};
  width: 600px;
  text-align: center;
`

const WriterText = styled(SemiBoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
  font-size: 20px;
  line-height: 1.5;
  margin-top: 27px;
`

const InterviewLink = styled(WriterText)`
  color: ${(p) => p.theme.color.primary};
  text-decoration: underline;
`

const FormContainer = styled(Column)`
  align-items: center;
`

const SliderWrapper = styled(Column)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    margin-top: 60px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    .slick-prev,
    .slick-next {
      top: 65px;
    }
  }
  width: 1144px;
  .slick-active {
    z-index: 1;
  }
`

const HeadingText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 14px;
  }
  font-size: 24px;
  line-height: 2;
  color: ${(p) => p.theme.color.primary4};
`

const MainText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    br {
      display: none;
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 32px;
    text-align: center;
  }
  font-size: 50px;
`

const Main = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 523px;
    padding: 0 16px;
  }
  height: 698px;
  align-items: center;
`
