import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BoldText } from 'components/common/text'
import Image from 'next/legacy/image'
import { useIsDesktop } from 'utils/hooks'

export const ScoutLandingStartupList: React.FC = () => {
  const isDesktop = useIsDesktop()
  const [isVisible, setIsVisible] = useState(false)
  const observingTarget = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!observingTarget.current) return
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isVisible) setIsVisible(true)
    })
    observer.observe(observingTarget.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main>
      <div ref={observingTarget} />
      {isVisible && (
        <Container isDesktop={isDesktop}>
          <HeadingText>이미 많은 실력있는 인재들이</HeadingText>
          <MainText>
            검증된 스타트업에 <br />
            합류했어요
          </MainText>
          <Grid>
            <LogoImage src='/episoden-logo-gray.svg' />
            <LogoImage src='/bio-research-ai-logo.svg' />
            <LogoImage src='/colca-logo.svg' />
            <LogoImage src='/fliption-logo.svg' />
            <LogoImage src='/lumigloo-logo.svg' />
            <LogoImage src='/hirediversity-logo.svg' />
            <LogoImage src='/buzz-beyond-logo.svg' />
            <LogoImage src='/recl-logo.svg' />
            <LogoImage src='/play-keyboard-logo.svg' />
            <LogoImage src='/vom-vom-logo.svg' />
            <LogoImage src='/alpha-circle-logo.svg' />
            <LogoImage src='/poza-labs-logo.svg' />
            <LogoImage src='/heroins-logo.svg' />
            <LogoImage src='/video-monster-logo.svg' />
            <LogoImage src='/your-make-logo.svg' />
          </Grid>
        </Container>
      )}
    </Main>
  )
}

const Container = styled(Column)<{ isDesktop: boolean }>`
  align-items: center;
  opacity: 1;
  @keyframes startupListFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: startupListFadeIn 1000ms 1 ease-in;
`

const Grid = styled(Row)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 52px;
  }
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  margin-top: 72px;
`

const LogoImage = styled(Image).attrs({
  width: 180,
  height: 120,
  alt: 'logo',
  priority: true,
})``

const HeadingText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 14px;
  }
  font-size: 24px;
  line-height: 1.5;
  color: ${(p) => p.theme.color.primary4};
`

const MainText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    br {
      display: none;
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 32px;
    text-align: center;
  }
  font-size: 50px;
`

const Main = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    padding: 80px 16px;
  }
  width: 996px;
  margin: auto;
  padding: 100px 0;
`
